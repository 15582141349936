@for $i from 1 through 10 {
    $location: (ml: margin-left, mt: margin-top, mr: margin-right, mb: margin-bottom, mg: margin, pl: padding-left, pt: padding-top, pr: padding-right, pb: padding-bottom, pd: padding);
    @each $a,
    $b in $location {
        .#{$a}#{5 * $i} {
            #{$b}: 5px * $i!important;
        }
    }
}

@for $i from 6 through 18 {
    .f#{2 * $i} {
        font-size: 2px * $i;
    }
}

@for $i from 10 through 20 {
    .lh#{2 * $i} {
        line-height: 2px * $i;
    }
}

.fl {
    float: left;
}

.fr {
    float: right;
}

.clear {
    clear: both;
}

.clearfix {
    *zoom: 1;
}

.clearfix:after {
    content: " ";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}

.ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.container {
    margin-right: auto;
    margin-left: auto;
}

.container:after,
.row:after,
.center,
.fl_box,
ul {
    @extend .clearfix;
}

.fl_box {
    > * {
        float: left;
    }
}

.content img {
    max-width: 100%;
}

.content_box {
    &,
    *,
    *:before,
    *:after {
        box-sizing: content-box;
    }
}

.tal {
    text-align: left;
}

.tac {
    text-align: center;
}

.tar {
    text-align: right;
}

.vat {
    vertical-align: top;
}

.vam {
    vertical-align: middle;
}

.vab {
    vertical-align: bottom;
}

.block {
    display: block;
}

.iblock {
    @include inline-block;
}

.posr {
    position: relative;
}

.posa {
    position: absolute;
}

.posf {
    position: fixed;
}


/* start-----banner小圆点容器-----start */

.pagination {
    position: absolute;
    z-index: 20;
    bottom: 20px;
    width: 100%;
    text-align: center;
}


/* end-----banner小圆点容器-----end */


/* start-----新闻详情-----start */

.introduction {
    margin-bottom: 20px;
    border: 1px solid #e5e5e5;
    padding: 5px 24px;
    background-color: #f5f5f5;
    font-size: 12px;
    color: #434343;
    line-height: 20px;
}

.news_title {
    text-align: center;
    padding-top: 30px;
    h1 {
        font-size: 18px;
        color: #434343;
        padding: 3px 0;
        font-weight: bold;
    }
    p {
        padding: 10px 0 15px;
        font-size: 12px;
        color: #ccc;
        span {
            padding: 0 15px;
        }
    }
}


/* end-----新闻详情-----end */
